import React from "react";
import moment from "moment";

// CLASSES
import Settings from "@classes/Settings";

// COMPONENTS
import Checkbox from "@components/Checkbox/Checkbox";
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import SignModal from "./SignModal/SignModal";

// HELPERS
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";
import api from "@helpers/api";

// OTHER
import fulfillment from "./SignAgreement.fulfillment";
import TextField from "../../../../../components/TextField/TextField";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import Button from "../../../../../components/Button/Button";
import Validator from "../../../../../classes/Validator";
import TextView from "../../../../../components/TextView/TextView";
import imgPdf from "@assets/img/ic_pdf.png";
import NumberField from "../../../components/NumberField/NumberField";
import Notice from "../../../../../components/Notice/Notice";
import AssistantModal from "../subcomponents/AssistantModal/AssistantModal";
import Select from "../../../../../components/Select/Select";
import {DocsList} from "../../../../../components/DocsList/DocsList.jsx";
import FileDrop from "../../../../../components/FileDrop/FileDrop.jsx";
import {decodeBase64Document} from "../../../../../helpers/utils.js";


export default class SignAgreement extends React.PureComponent
{
	static customerSourceOptions = [
		{label: "KA", value: "KA"},
		{label: "Leads", value: "Leads"},
		{label: "Referens", value: "Referens"},
		{label: "Eget nätverk", value: "Eget nätverk"},
		{label: "Ärvd kund", value: "Ärvd kund"},
		{label: "Annat", value: "Annat"}
	];

	static leadsSourceOptions = [
		{label: "Av Fair anordnat event", value: "Av Fair anordnat event"},
		{label: "Av Fair anordnat seminarie", value: "Av Fair anordnat seminarie"},
		{label: "JB", value: "JB"},
		{label: "COO", value: "COO"}
	];

	static referensSourceOptions = [
		{label: "Ej kund", value: "Ej kund"},
		{label: "Befintlig kund", value: "Befintlig kund"}
	];

	static networkSourceOptions = [
		{label: "Av rådgivaren kontaktad kund", value: "Av rådgivaren kontaktad kund"},
		{label: "Av rådgivaren anordnat event", value: "Av rådgivaren anordnat event"},
		{label: "BEN", value: "BEN"},
		{label: "BEN & Breakfast", value: "BEN & Breakfast"},
		{label: "BNI", value: "BNI"},
		{label: "Halfway House", value: "Halfway House"},
		{label: "LinkedIn", value: "LinkedIn"},
		{label: "Skiers Accredited", value: "Skiers Accredited"},
	];

	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state)
	{
		return fulfillment(state, this.state)
	}

	setCustomerState = (customerState, callbackFn) =>
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	};

	componentDidMount()
	{
		const {setAppState, user} = this.props;
		const signButtonText = user.role === "assistant" ? i18n("cob", "sign_agreement_assistant") : i18n("cob", "sign_agreement");

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {label: signButtonText, appearance: "success"}
			},
		});
	}

	render()
	{
		const {props, state} = this;
		const {
			hasFormValidationErrors,
			signModalVisible,
			signModalBusy,
			signModalData,
			onRestartSession,
			customer,
			user,
			advisor
		} = props;
		const {customerState} = state;
		const classes = createClassName("Cob-view Cob-SignAgreement", {});
		const signDate = moment();

		const totalDocsSize = state.docs.reduceRight((a, b) => a + Math.round(b.size / 1024), 0);
		const maxUploadFileSize = Math.round(state.maxUploadFileSize / 1024);

		const commissionSharing = [];
		let totalCommissionShare = 0;
		for (let i = 1; i <= customerState.commission_sharing_number_of_advisors; i++)
		{
			const advisorShare = customerState["commission_sharing_advisor_share_" + i];
			if (!isNaN(advisorShare) && advisorShare > 0 && advisorShare <= 100)
			{
				totalCommissionShare += parseFloat(advisorShare);
			}

			commissionSharing.push(
				<StackLayout className="Cob-fields-collection" rowWrap fullWidth key={"commission_sharing_" + i} style={{marginBottom: "10px !important"}}>
					<div>
						<TextField
							name={"commission_sharing_advisor_name_" + i}
							value={customerState["commission_sharing_advisor_name_" + i]}
							label="Rådgivarens namn"
							validation={value => value.length > 0}
							onChange={({target}) =>
							{
								customerState["commission_sharing_advisor_name_" + i] = target.value;
								this.setCustomerState(customerState);
							}}
							highlightErrors={hasFormValidationErrors}
						/>
					</div>
					<div style={{maxWidth: "150px"}}>
						<NumberField
							name={"commission_sharing_advisor_share_" + i}
							value={customerState["commission_sharing_advisor_share_" + i]}
							unit="%"
							label="Andel"
							validation={value => value.length > 0 && value >= 0 && value <= 100}
							onChange={({target}) =>
							{
								customerState["commission_sharing_advisor_share_" + i] = target.value;
								this.setCustomerState(customerState);
							}}
							highlightErrors={hasFormValidationErrors}
						/>
					</div>
				</StackLayout>
			);
		}

		const companySignatories = [];
		if (customer.isCompany)
		{
			companySignatories.push(
				<React.Fragment key={0}>
					<div className="h-line spacing"/>
					<h3 style={{marginBottom: "5px"}}>Signatärer</h3>
					<p>
						Vilka skall underteckna detta avtal?
					</p>
				</React.Fragment>
			);

			for (let i = 0; i < customerState.company_active_signatories; i++)
			{
				companySignatories.push(
					<React.Fragment key={i + 1}>
						<h4 style={{marginBottom: "5px"}}>Signatär {i + 1}</h4>
						<StackLayout className="Cob-fields-collection" rowWrap fullWidth>
							<div>
								<TextField
									name={"company_signatory_firstname_" + i}
									value={customerState["company_signatory_firstname_" + i]}
									label="Förnamn"
									validation={value => value.length > 0}
									onChange={({target}) =>
									{
										customerState["company_signatory_firstname_" + i] = target.value;
										this.setCustomerState(customerState);
									}}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<TextField
									name={"company_signatory_lastname_" + i}
									value={customerState["company_signatory_lastname_" + i]}
									label="Efternamn"
									validation={value => value.length > 0}
									onChange={({target}) =>
									{
										customerState["company_signatory_lastname_" + i] = target.value;
										this.setCustomerState(customerState);
									}}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<TextField
									name={"company_signatory_email_" + i}
									value={customerState["company_signatory_email_" + i]}
									label="E-postadress"
									type="email"
									validation={Validator.validateEmail}
									onChange={({target}) =>
									{
										customerState["company_signatory_email_" + i] = target.value;
										this.setCustomerState(customerState);
									}}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<TextField
									name={"company_signatory_sec_num_" + i}
									value={customerState["company_signatory_sec_num_" + i]}
									label="Personnummer"
									type="number"
									validation={value => value.length === 12 && (value.substring(0, 2) === "19" || value.substring(0, 2) === "20")}
									onChange={({target}) =>
									{
										customerState["company_signatory_sec_num_" + i] = target.value;
										this.setCustomerState(customerState);
									}}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
						</StackLayout>
					</React.Fragment>
				);
			}

			companySignatories.push(
				<React.Fragment key={100}>
					<StackLayout fullWidth>
						<Button
							size="small"
							label="Ta bort sista"
							appearance={customerState.company_active_signatories <= 1 ? "medium" : "danger"}
							disabled={customerState.company_active_signatories <= 1}
							onClick={() => this.setCustomerState({company_active_signatories: customerState.company_active_signatories - 1})}
						/>
						<Button
							size="small"
							label="Lägg till signatär"
							appearance={customerState.company_active_signatories === 4 ? "medium" : "primary"}
							disabled={customerState.company_active_signatories === 4}
							onClick={() => this.setCustomerState({company_active_signatories: customerState.company_active_signatories + 1})}
							filled
						/>
					</StackLayout>
				</React.Fragment>
			);
		}

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				{signModalVisible && user.role !== "assistant" && (
					<SignModal
						busy={signModalBusy}
						visible={signModalVisible}
						data={signModalData}
						status={state.signStatus}
						errorMessage={state.signErrorMessage}
						signUrls={state.signUrls}
						user={user}
						customer={customer}
						onSign={this._onSignModalSign}
						onClose={this._onSignModalClose}
						onRetry={this._onSignModalRetry}
						onRestart={onRestartSession}
					/>
				)}

				{signModalVisible && user.role === "assistant" && (
					<AssistantModal
						busy={signModalBusy}
						visible={signModalVisible}
						user={user}
						advisor={advisor}
						onSend={this._onSignModalSign}
						onClose={this._onSignModalClose}
						onRestart={onRestartSession}
					/>
				)}

				<input type="hidden" name="advice_sign_date" value={signDate.format("YYYY-MM-DD")}/>
				<input type="hidden" name="checkbox_customer_aware" value={"y"}/>

				<ContentBox>
					{hasFormValidationErrors && (
						<div className="Cob-SignAgreement-error">
							Du har inte fyllt i alla obligatoriska fält i rådgivningen.<br/>
							Var god komplettera den saknade informationen och försök på nytt.
						</div>
					)}
					<h2>Bilagor och underskrift</h2>

					<div className="Cob-SignAgreement-fields">

						<h3 style={{marginBottom: "20px"}}>Följande bilagor är utdelade och genomgångna med kund</h3>

						<Checkbox
							name="advice_sign_row_1"
							label="Information om Fair Investments Sweden AB"
							value="Ja"
							checked={customerState.advice_sign_row_1 === "Ja"}
							onChange={checked => this.setCustomerState({advice_sign_row_1: checked ? "Ja" : "Nej"})}
							alignMiddle
							style={{marginBottom: "20px", paddingRight: "30px"}}
							highlightErrors={hasFormValidationErrors}
						/>

						<Checkbox
							name="advice_sign_row_2"
							label="Allmänna bestämmelser för depå"
							value="Ja"
							checked={customerState.advice_sign_row_2 === "Ja"}
							onChange={checked => this.setCustomerState({advice_sign_row_2: checked ? "Ja" : "Nej"})}
							alignMiddle
							style={{marginBottom: "20px", paddingRight: "30px"}}
							highlightErrors={hasFormValidationErrors}
						/>

						<Checkbox
							name="advice_sign_row_3"
							label="Riktlinjer för bästa utförande/vidareförmedling av order"
							value="Ja"
							checked={customerState.advice_sign_row_3 === "Ja"}
							onChange={checked => this.setCustomerState({advice_sign_row_3: checked ? "Ja" : "Nej"})}
							alignMiddle
							style={{marginBottom: "20px", paddingRight: "30px"}}
							highlightErrors={hasFormValidationErrors}
						/>

						<Checkbox
							name="advice_sign_row_4"
							label="Information om skillnader i ersättning"
							value="Ja"
							checked={customerState.advice_sign_row_4 === "Ja"}
							onChange={checked => this.setCustomerState({advice_sign_row_4: checked ? "Ja" : "Nej"})}
							alignMiddle
							style={{marginBottom: "20px", paddingRight: "30px"}}
							highlightErrors={hasFormValidationErrors}
						/>

						<Checkbox
							name="advice_sign_row_5"
							label="Information om egenskaper och risker avseende finansiella instrument"
							value="Ja"
							checked={customerState.advice_sign_row_5 === "Ja"}
							onChange={checked => this.setCustomerState({advice_sign_row_5: checked ? "Ja" : "Nej"})}
							alignMiddle
							style={{marginBottom: "20px", paddingRight: "30px"}}
							highlightErrors={hasFormValidationErrors}
						/>

						<Checkbox
							name="advice_sign_row_6"
							label="Allmänna villkor för handel med finansiella instrument"
							value="Ja"
							checked={customerState.advice_sign_row_6 === "Ja"}
							onChange={checked => this.setCustomerState({advice_sign_row_6: checked ? "Ja" : "Nej"})}
							alignMiddle
							style={{marginBottom: "20px", paddingRight: "30px"}}
							highlightErrors={hasFormValidationErrors}
						/>

						<Checkbox
							name="advice_sign_row_8"
							label="Information om hållbarhetspreferenser"
							value="Ja"
							checked={customerState.advice_sign_row_8 === "Ja"}
							onChange={checked => this.setCustomerState({advice_sign_row_8: checked ? "Ja" : "Nej"})}
							alignMiddle
							style={{marginBottom: "20px", paddingRight: "30px"}}
							highlightErrors={hasFormValidationErrors}
						/>

						<Checkbox
							name="advice_sign_row_7"
							label="Kopia på dessa grunduppgifter har delats ut"
							value="Ja"
							checked={customerState.advice_sign_row_7 === "Ja"}
							onChange={checked => this.setCustomerState({advice_sign_row_7: checked ? "Ja" : "Nej"})}
							alignMiddle
							style={{marginBottom: "20px", paddingRight: "30px"}}
							highlightErrors={hasFormValidationErrors}
						/>

						<p style={{margin: "10px 0 30px 0"}}>
							<a href="http://assets.fairinvestments.se/fair-bilagor.pdf" rel="noopener noreferrer"
							   target="_blank" style={{
								display: "flex",
								alignItems: "center"
							}}>
								<img src={imgPdf} style={{height: "50px", marginRight: "10px"}} alt="Ladda ner"/>
								Ladda ner bilagor som PDF.
							</a>
						</p>

						{companySignatories}

						<div className="h-line spacing"/>

						<h4 style={{margin: "0 0 10px 0"}}>Försättsblad</h4>
						<p style={{marginBottom: "0"}}>
							<strong>Test, använd ej.</strong> Sida som läggs först i dokumentationen. (<em>Valfritt</em>)
						</p>
						<Checkbox
							name="advice_include_front_page"
							label="Jag vill ladda upp ett försättsblad"
							value="yes"
							checked={customerState.advice_include_front_page === "yes"}
							onChange={checked => this.setCustomerState({advice_include_front_page: checked ? "yes" : "no"})}
							alignMiddle
							style={{marginBottom: "0", paddingRight: "30px"}}
							highlightErrors={hasFormValidationErrors}
						/>

						{customerState.advice_include_front_page === "yes" && (
							<React.Fragment>
								<FileDrop
									filterKey={"first-page"}
									dropText="Dra och släpp PDF:er här"
									busyText="Laddar in dokument..."
									accept="application/pdf"
									onDrop={this._onDocDrop}
									style={{marginTop: "20px", marginBottom: "5px"}}
								/>

								<DocsList
									filterKey={"first-page"}
									items={state.docs}
									totalSize={totalDocsSize}
									maxSize={maxUploadFileSize}
									onDocRemove={this._onDocRemove}
								/>
							</React.Fragment>
						)}

						<h4 style={{margin: "40px 0 10px 0"}}>Provisionsfördelning</h4>
						{commissionSharing}
						<div style={{flexDirection: "row", marginBottom: "20px"}}>
							{state.customerState.commission_sharing_number_of_advisors < 4 && (
								<Button
									size="small"
									label="Lägg till rådgivare"
									appearance={"primary"}
									onClick={() => this.setCustomerState({commission_sharing_number_of_advisors: (state.customerState.commission_sharing_number_of_advisors + 1)})}
									filled
									style={{marginRight: "15px"}}
								/>
							)}
							{state.customerState.commission_sharing_number_of_advisors > 1 && (
								<Button
									size="small"
									label="Ta bort rådgivare"
									appearance={"primary"}
									onClick={() => this.setCustomerState({commission_sharing_number_of_advisors: (state.customerState.commission_sharing_number_of_advisors - 1)})}
								/>
							)}
						</div>

						{totalCommissionShare !== 100 && (
							<Notice style={{marginTop: 10}} appearance="warning">
								Rådgivarnas provisionsfördelning summerar ej till 100%.
							</Notice>
						)}

						<h4 style={{margin: "20px 0 10px 0"}}>Hur fick du kontakt med kunden?</h4>
						<Select
							style={{margin: "0 0 10px 0"}}
							name="customer_source"
							label="Välj"
							options={SignAgreement.customerSourceOptions}
							selectedIndex={SignAgreement.customerSourceOptions.map(c => c.value).indexOf(customerState.customer_source)}
							onChange={(option) => this.setCustomerState({customer_source: option.value})}
							highlightErrors={hasFormValidationErrors}
						/>

						{customerState.customer_source === "Leads" && (
							<Select
								style={{margin: "0 0 10px 0"}}
								name="customer_source_leads"
								label="Välj typ"
								options={SignAgreement.leadsSourceOptions}
								selectedIndex={SignAgreement.leadsSourceOptions.map(c => c.value).indexOf(customerState.customer_source_leads)}
								onChange={(option) => this.setCustomerState({customer_source_leads: option.value})}
								highlightErrors={hasFormValidationErrors}
							/>
						)}

						{customerState.customer_source === "Referens" && (
							<Select
								style={{margin: "0 0 10px 0"}}
								name="customer_source_referens"
								label="Välj typ av referens"
								options={SignAgreement.referensSourceOptions}
								selectedIndex={SignAgreement.referensSourceOptions.map(c => c.value).indexOf(customerState.customer_source_referens)}
								onChange={(option) => this.setCustomerState({customer_source_referens: option.value})}
								highlightErrors={hasFormValidationErrors}
							/>
						)}

						{customerState.customer_source === "Referens" && customerState.customer_source_referens === "Befintlig kund" && (
							<TextField
								name={"customer_source_referens_who"}
								value={customerState.customer_source_referens_who}
								label="Namn på befintlig kund"
								validation={value => value.length > 0}
								onChange={({target}) => this.setCustomerState({customer_source_referens_who: target.value})}
								highlightErrors={hasFormValidationErrors}
							/>
						)}

						{customerState.customer_source === "Eget nätverk" && (
							<Select
								style={{margin: "0 0 10px 0"}}
								name="customer_source_network"
								label="Välj"
								options={SignAgreement.networkSourceOptions}
								selectedIndex={SignAgreement.networkSourceOptions.map(c => c.value).indexOf(customerState.customer_source_network)}
								onChange={(option) => this.setCustomerState({customer_source_network: option.value})}
								highlightErrors={hasFormValidationErrors}
							/>
						)}

						{customerState.customer_source === "Annat" && (
							<TextField
								name={"customer_source_other_text"}
								value={customerState.customer_source_other_text}
								label="Ange hur du fick kontakt med kunden"
								validation={value => value.length > 0}
								onChange={({target}) => this.setCustomerState({customer_source_other_text: target.value})}
								highlightErrors={hasFormValidationErrors}
							/>
						)}

						{customerState.customer_source === "Ärvd kund" && (
							<TextField
								name={"customer_source_inherited_text"}
								value={customerState.customer_source_inherited_text}
								label="Ange från vilken rådgivare kunden är ärvd"
								validation={value => value.length > 0}
								onChange={({target}) => this.setCustomerState({customer_source_inherited_text: target.value})}
								highlightErrors={hasFormValidationErrors}
							/>
						)}

						<h4 style={{margin: "30px 0 10px 0"}}>Meddelande till backoffice</h4>
						<p style={{margin: "0 0 10px 0"}}>
							Information till BO såsom eventuella avvikande villkor som ska godkännas av ansvarig
							förvaltare.
						</p>
						<TextView
							name="backoffice_notes"
							label={`Meddelande (${2000 - customerState.backoffice_notes.length} tecken kvar)`}
							value={customerState.backoffice_notes}
							maxLength={2000}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({backoffice_notes: target.value})}
							fullWidth
							minHeight={250}
						/>
					</div>
				</ContentBox>
			</div>
		);
	}

	// Internal methods

	_onDocDrop = (documents) => {
		const {props, state} = this;
		const {customer} = props;
		const docs = [...state.docs];
		const docNames = [...state.docNames];

		for (let i = 0, n = documents.length; i < n; i++) {
			const doc = documents[i];

			// Prevent duplicates
			if (docNames.indexOf(doc.name) !== -1) continue;

			docs.push({name: doc.name, size: doc.size, filterKey: doc.filterKey});
			docNames.push(doc.name);

			customer.addDocument({
				name: doc.name,
				size: doc.size,
				data: doc.data, // doc.data.substr(0, 100),
				filterKey: doc.filterKey
			});
		}

		this.setState({docs, docNames, isFulfilled: this.isFulfilled({docs, docNames})}, () => {
			props.setAppState({viewFulfilled: this.state.isFulfilled});
		});
	};

	_onDocRemove = (item) =>
	{
		const {props, state} = this;
		const {customer} = props;

		const docs = [...state.docs].filter((d) =>
		{
			return d.name !== item.name && d.document_name !== item.name;
		});

		const docNames = [...state.docNames].filter((d) =>
		{
			return d !== item.name && d !== item.document_name;
		});

		customer.removeDocumentWithName(item.name);
		this.setState({docs, docNames});
	}

	_onSignModalClose = async () =>
	{
		const {setAppState} = this.props;
		await setAppState({signModalVisible: false});
	};

	_onSignModalSign = ({signatories, signOnScreen}) =>
	{
		return new Promise(async resolve =>
		{
			const {props} = this;
			const {user, setAppState, customer} = props;
			const newStateProps = {};

			await setAppState({signModalBusy: true});

			const excludedDocuments = [];

			if (!customer.isCompany)
			{
				excludedDocuments.push(1); // Remove main documentation "Juridisk person"
				excludedDocuments.push(6); // Remove documentation about Huvudman
				console.log("Sign :: Private individual => Excluding company specific docs");
			}
			else
			{
				excludedDocuments.push(0); // Remove main documentation "Fysisk person"
				console.log("Sign :: Company => Excluding person specific docs");
			}

			/**
			 * If this is a new customer placement we should use one of the Diskretionär förvaltning agreements,
			 * otherwise the tilläggsavtal is selected.
			 */
			let isNewPlacement = true;
			if ((customer.getData("allocation_amount_guru").length > 0) ||
				(customer.getData("allocation_amount_spets").length > 0) ||
				(customer.getData("allocation_amount_svan").length > 0) ||
				(customer.getData("allocation_amount_other").length > 0) ||
				(customer.getData("allocation_amount_advice").length > 0))
			{
				if (customer.getData("customer_guru_depa") ||
					customer.getData("customer_spets_depa") ||
					customer.getData("customer_svan_depa") ||
					customer.getData("customer_other_depa"))
				{
					isNewPlacement = false; // Tilläggsplacering
				}
			}
			else
			{
				isNewPlacement = false; // Byte av avgiftsmodell
			}

			if (customer.getData("radio_product_selection_mode") === "nochange")
			{
				// No documents at all for "no placement"
				excludedDocuments.push(5); // Tilläggsavtal
				excludedDocuments.push(10); // Tilläggsavtal alternativ version
				excludedDocuments.push(2); // Depåhandlingar
				excludedDocuments.push(3); // "Avtal om diskretionär förvaltning" Guru / Spets
				excludedDocuments.push(4); // "Avtal om diskretionär förvaltning" Ädelmetall
				excludedDocuments.push(7); // ISK
				excludedDocuments.push(9); // "Avtal om diskretionär förvaltning" Svart Svan
				console.log("Sign :: Excluding \"Tilläggsavtal\"");
				console.log("Sign :: Excluding \"Tilläggsavtal (Alt)\"");
				console.log("Sign :: Excluding \"Depåhandlingar\"");
				console.log("Sign :: Excluding \"Avtal om diskretionär förvaltning Guru/Spets\"");
				console.log("Sign :: Excluding \"Avtal om diskretionär förvaltning Ädelmetall\"");
				console.log("Sign :: Excluding \"Avtal om diskretionär förvaltning Svart Svan\"");
				console.log("Sign :: Excluding ISK documentation");
			}
			else if (isNewPlacement)
			{
				excludedDocuments.push(5); // Tilläggsavtal should not be included for new placements
				excludedDocuments.push(10); // Tilläggsavtal alternativ version
				console.log("Sign :: Excluding \"Tilläggsavtal\"");
				console.log("Sign :: Excluding \"Tilläggsavtal (Alt)\"");
			}
			else  // Tilläggsplacering
			{
				excludedDocuments.push(2); // Depåhandlingar
				excludedDocuments.push(3); // "Avtal om diskretionär förvaltning" Guru / Spets
				excludedDocuments.push(4); // "Avtal om diskretionär förvaltning" Ädelmetall
				excludedDocuments.push(7); // ISK
				excludedDocuments.push(9); // "Avtal om diskretionär förvaltning" Svart Svan
				console.log("Sign :: Excluding \"Depåhandlingar\"");
				console.log("Sign :: Excluding \"Avtal om diskretionär förvaltning Guru/Spets\"");
				console.log("Sign :: Excluding \"Avtal om diskretionär förvaltning Ädelmetall\"");
				console.log("Sign :: Excluding \"Avtal om diskretionär förvaltning Svart Svan\"");
				console.log("Sign :: Excluding ISK documentation");

				// Leave the correct Tilläggsavtal, depending on whether we checked the "alternative" box or not
				if (customer.getData("checkbox_addon_placement_alternative") === "yes")
				{
					excludedDocuments.push(5); // Standard Tilläggsavtal should not be included if alt version is requested
					console.log("Sign :: Excluding \"Tilläggsavtal\"");
				}
				else
				{
					excludedDocuments.push(10); // Alt Tilläggsavtal should not be included if standard version is requested
					console.log("Sign :: Excluding \"Tilläggsavtal (Alt)\"");
				}
			}

			// Code to handle which Diskretionär förvaltning documents should be included when doing new placements
			if (isNewPlacement)
			{
				/**
				 * Spets or Guru hasn't been selected - don't include documentation
				 */
				if (customer.getData("allocation_amount_guru").length <= 0 &&
					customer.getData("allocation_amount_spets").length <= 0)
				{
					excludedDocuments.push(3); // "Avtal om diskretionär förvaltning" Guru / Spets
					console.log("Sign :: Excluding \"Avtal om diskretionär förvaltning Guru/Spets\"");
				}

				/**
				 * Other/Ädelmetall hasn't been selected - don't include documentation
				 */
				if (customer.getData("allocation_amount_other").length <= 0)
				{
					excludedDocuments.push(4); // "Avtal om diskretionär förvaltning" Ädelmetall
					console.log("Sign :: Excluding \"Avtal om diskretionär förvaltning Ädelmetall\"");
				}

				/**
				 * Black Swan hasn't been selected - don't include documentation
				 */
				if (customer.getData("allocation_amount_svan").length <= 0)
				{
					excludedDocuments.push(9); // "Avtal om diskretionär förvaltning" Svart Svan
					console.log("Sign :: Excluding \"Avtal om diskretionär förvaltning Svart Svan\"");
				}

				/**
				 * If an ISK account is not opened we should not include the ISK documentation
				 */
				if (customer.getData("checkbox_account_isk") !== 'Ja')
				{
					excludedDocuments.push(7);
					console.log("Sign :: Excluding ISK documentation");
				}
			}

			/*
			 * Exclude documentation about Autogiroanmälan until this has been implemented.
			 */
			if (customer.getData("radio_customer_autogiro") !== 'yes')
			{
				excludedDocuments.push(8);
				console.log("Sign :: Excluding Autogiro documentation");
			}

			/**
			 * Also make sure correct attachments are included (bilagor).
			 */
			const datasheetUrls = [];
			if (customer.getData("allocation_amount_svan").length > 0)
			{
				console.log("Sign :: Attaching appendix svart-svan-bilaga-1.pdf");
				datasheetUrls.push("https://assets.fairinvestments.se/bilagor/svart-svan-bilaga-1.pdf");
			}

			if (customer.getData("allocation_amount_other").length > 0)
			{
				console.log("Sign :: Attaching appendix adelmet-bilaga-4.pdf");
				datasheetUrls.push("https://assets.fairinvestments.se/bilagor/adelmet-bilaga-4.pdf");
			}

			if (customer.getData("allocation_amount_guru").length > 0)
			{
				console.log("Sign :: Attaching appendix guru-bilaga-1.pdf");
				console.log("Sign :: Attaching appendix guru-bilaga-2.pdf");
				datasheetUrls.push("https://assets.fairinvestments.se/bilagor/guru-bilaga-1.pdf");
				datasheetUrls.push("https://assets.fairinvestments.se/bilagor/guru-bilaga-2.pdf");
			}

			if (customer.getData("allocation_amount_spets").length > 0)
			{
				console.log("Sign :: Attaching appendix spets-bilaga-1.pdf");
				console.log("Sign :: Attaching appendix spets-bilaga-2.pdf");
				datasheetUrls.push("https://assets.fairinvestments.se/bilagor/spets-bilaga-1.pdf");
				datasheetUrls.push("https://assets.fairinvestments.se/bilagor/spets-bilaga-2.pdf");
			}

			if (customer.getData("checkbox_spets_mjukstart") === "yes")
			{
				console.log("Sign :: Attaching appendix spets-bilaga-mjukstart.pdf");
				datasheetUrls.push("https://assets.fairinvestments.se/bilagor/spets-bilaga-mjukstart.pdf");
			}

			try
			{
				// Sign the case and receive its sign URL
				const {signUrls} = await api("/case/sign", {
					caseId: Settings.get("CASE_ID"),
					signatories,
					signOnScreen,
					partner: user.getStakeholderData(),
					excludeDocs: excludedDocuments,
					datasheetUrls
				});

				newStateProps.signStatus = "success";
				newStateProps.signUrls = signUrls;
			}
			catch (e)
			{
				console.error(e.message);
				newStateProps.signStatus = "fail";
				newStateProps.signErrorMessage = e.message;
			}

			this.setState(newStateProps, async () =>
			{
				await setAppState({signModalBusy: false});
				resolve();
			});
		});
	};

	_onSignModalRetry = () =>
	{
		this.setState({
			signStatus: null,
			signUrls: null,
			signErrorMessage: null,
		});
	}
}


// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer} = this.props;

	let newState = getViewInitialStateForCustomer(customer, this.props.advisor);
	newState.isFulfilled = this.isFulfilled(newState);
	if (newState.customerState.commission_sharing_advisor_name_1.length <= 0)
	{
		newState.customerState.commission_sharing_advisor_name_1 = this.props.user.firstName + " " + this.props.user.lastName;
	}
	return newState;
}

export function getViewInitialStateForCustomer(customer, advisor)
{
	const newState = {
		signStatus: null,
		signUrls: null,
		signErrorMessage: null,

		docs: customer.documents.map(doc =>
		{
			// get document size from raw_data if property exists
			const decodedDoc = decodeBase64Document(doc);
			return {
				name: doc.name || doc.document_name,
				size: doc.size || decodedDoc.length,
				filterKey: doc.filterKey || ""
			}
		}),
		docNames: customer.documents.map(doc => doc.name || doc.document_name),
		maxUploadFileSize: customer.maxUploadFileSize,

		customerState: {
			isCompany: customer.isCompany,
			no_placement_done: customer.getData("checkbox_advice_skip_placement") === "yes",

			advice_sign_row_1: customer.getData("advice_sign_row_1"),
			advice_sign_row_2: customer.getData("advice_sign_row_2"),
			advice_sign_row_3: customer.getData("advice_sign_row_3"),
			advice_sign_row_4: customer.getData("advice_sign_row_4"),
			advice_sign_row_5: customer.getData("advice_sign_row_5"),
			advice_sign_row_6: customer.getData("advice_sign_row_6"),
			advice_sign_row_7: customer.getData("advice_sign_row_7"),
			advice_sign_row_8: customer.getData("advice_sign_row_8"),
			advice_include_front_page: customer.getData("advice_include_front_page"),

			backoffice_notes: customer.getData("backoffice_notes") || "",

			company_active_signatories: 1,

			company_signatory_firstname_0: customer.getData("customer_inv_poa_firstname"),
			company_signatory_lastname_0: customer.getData("customer_inv_poa_lastname"),
			company_signatory_email_0: customer.getData("company_signatory_email_0"),
			company_signatory_sec_num_0: customer.getData("customer_inv_poa_ssn"),

			company_signatory_firstname_1: customer.getData("customer_inv_poa_firstname_2"),
			company_signatory_lastname_1: customer.getData("customer_inv_poa_lastname_2"),
			company_signatory_email_1: customer.getData("company_signatory_email_1"),
			company_signatory_sec_num_1: customer.getData("customer_inv_poa_ssn_2"),

			company_signatory_firstname_2: customer.getData("customer_inv_poa_firstname_3"),
			company_signatory_lastname_2: customer.getData("customer_inv_poa_lastname_3"),
			company_signatory_email_2: customer.getData("company_signatory_email_2"),
			company_signatory_sec_num_2: customer.getData("customer_inv_poa_ssn_3"),

			company_signatory_firstname_3: customer.getData("customer_inv_poa_firstname_4"),
			company_signatory_lastname_3: customer.getData("customer_inv_poa_lastname_4"),
			company_signatory_email_3: customer.getData("company_signatory_email_3"),
			company_signatory_sec_num_3: customer.getData("customer_inv_poa_ssn_4"),

			commission_sharing_number_of_advisors: 1,

			commission_sharing_advisor_name_1: customer.getData("commission_sharing_advisor_name_1"),
			commission_sharing_advisor_share_1: customer.getData("commission_sharing_advisor_share_1") ? customer.getData("commission_sharing_advisor_share_1").replaceAll("%", "") : 100,

			commission_sharing_advisor_name_2: customer.getData("commission_sharing_advisor_name_2"),
			commission_sharing_advisor_share_2: customer.getData("commission_sharing_advisor_share_2") ? customer.getData("commission_sharing_advisor_share_2").replaceAll("%", "") : "",

			commission_sharing_advisor_name_3: customer.getData("commission_sharing_advisor_name_3"),
			commission_sharing_advisor_share_3: customer.getData("commission_sharing_advisor_share_3") ? customer.getData("commission_sharing_advisor_share_3").replaceAll("%", "") : "",

			commission_sharing_advisor_name_4: customer.getData("commission_sharing_advisor_name_4"),
			commission_sharing_advisor_share_4: customer.getData("commission_sharing_advisor_share_4") ? customer.getData("commission_sharing_advisor_share_4").replaceAll("%", "") : "",

			customer_source: customer.getData("customer_source"),
			customer_source_leads: customer.getData("customer_source_leads"),
			customer_source_referens: customer.getData("customer_source_referens"),
			customer_source_referens_who: customer.getData("customer_source_referens_who"),
			customer_source_network: customer.getData("customer_source_network"),
			customer_source_other_text: customer.getData("customer_source_other_text"),
			customer_source_inherited_text: customer.getData("customer_source_inherited_text")
		}
	};

	if (newState.customerState.company_signatory_firstname_3 !== '')
	{
		newState.customerState.company_active_signatories = 4;
	}
	else if (newState.customerState.company_signatory_firstname_2 !== '')
	{
		newState.customerState.company_active_signatories = 3;
	}
	else if (newState.customerState.company_signatory_firstname_1 !== '')
	{
		newState.customerState.company_active_signatories = 2;
	}

	newState.customerState.commission_sharing_number_of_advisors =
		newState.customerState.commission_sharing_advisor_name_4 !== '' ? 4 :
			newState.customerState.commission_sharing_advisor_name_3 !== '' ? 3 :
				newState.customerState.commission_sharing_advisor_name_2 !== '' ? 2 : 1;

	if (newState.customerState.commission_sharing_advisor_name_1.length <= 0)
	{
		newState.customerState.commission_sharing_advisor_name_1 = advisor.firstName + " " + advisor.lastName;
	}

	return newState;
}

function _getFields()
{
	return [
		"advice_sign_row_1",
		"advice_sign_row_2",
		"advice_sign_row_3",
		"advice_sign_row_4",
		"advice_sign_row_5",
		"advice_sign_row_6",
		"advice_sign_row_7",
		"advice_sign_row_8",
		"advice_include_front_page",

		"company_signatory_firstname_0",
		"company_signatory_lastname_0",
		"company_signatory_email_0",
		"company_signatory_sec_num_0",

		"company_signatory_firstname_1",
		"company_signatory_lastname_1",
		"company_signatory_email_1",
		"company_signatory_sec_num_1",

		"company_signatory_firstname_2",
		"company_signatory_lastname_2",
		"company_signatory_email_2",
		"company_signatory_sec_num_2",

		"company_signatory_firstname_3",
		"company_signatory_lastname_3",
		"company_signatory_email_3",
		"company_signatory_sec_num_3",
		"no_placement_done",

		"commission_sharing_advisor_name_1",
		"commission_sharing_advisor_share_1",

		"commission_sharing_advisor_name_2",
		"commission_sharing_advisor_share_2",

		"commission_sharing_advisor_name_3",
		"commission_sharing_advisor_share_3",

		"commission_sharing_advisor_name_4",
		"commission_sharing_advisor_share_4",

		"customer_source",
		"customer_source_leads",
		"customer_source_referens",
		"customer_source_referens_who",
		"customer_source_network",
		"customer_source_other_text",
		"customer_source_inherited_text",
		"backoffice_notes",
	];
}